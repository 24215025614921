import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutPage = () => (
    <Layout>
        <SEO title="About Alisha" />
        <header className="small">
            <h2>About Alisha</h2>
            <p>Artist, Mother</p>
        </header>
        <div className="inner">
            <p>
                A little bit about me....I have a wonderful husband and we have
                four kids together. We have a daughter, a son and then twin
                daughters! I have been an artist all my life. When I was in the
                6th grade is when I really took off after an art teacher pushed
                me beyond my comfort zone. I didn't start painting until 2010
                and just fell in love with it. I also do graphite portraits. I
                do some semi abstract, semi impressionist paintings and I also
                do commissions!
            </p>
            <p style={{ textAlign: 'center' }}>
                <img
                    src={require('../images/original.jpg')}
                    style={{ width: 100 }}
                />
            </p>
        </div>
    </Layout>
);

export default AboutPage;
